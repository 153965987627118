import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Voorwaarden from '../assets/VHG_Algemene_Consumentenvoorwaarden.pdf';
import Button from '../components/Button';

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>Garden Jobs | 404</title>
      </Helmet>

      <Header title='Pagina niet gevonden' />

      <div className='container vhg'>
        <section className='container vhg__container'>
          <h2 className='vhg__title'>Oeps!</h2>

          <p>
            Deze pagina lijkt verdwaald te zijn in de struiken. Ga terug naar de
            homepage en ontdek onze bloeiende website!
          </p>

          <Button path='/' text='Home'></Button>
        </section>
      </div>
    </>
  );
};

export default NotFoundPage;
