import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Voorwaarden from '../assets/VHG_Algemene_Consumentenvoorwaarden.pdf';

const VHGPage = () => {
  return (
    <>
      <Helmet>
        <title>Garden Jobs | VHG Voorwaarden</title>
      </Helmet>

      <Header title='VHG Voorwaarden' />

      <div className='container vhg'>
        <section className='container vhg__container'>
          <h2 className='vhg__title'>
            Als VHG-hovenier zorgen wij voor meer tuinplezier!
          </h2>

          <p>
            Garden Jobs is aangesloten bij de branchevereniging Koninklijke
            Vereniging van Hoveniers en Groenvoorzieners (VHG).
          </p>

          <h3 className='vhg__subtitle'>Vakmanschap</h3>

          <p>
            Wanneer u in zee gaat met Garden Jobs, dan kunt u erop rekenen op:
          </p>

          <ul>
            <li>vakkundige aanleg of renovatie van uw tuin;</li>
            <li>deskundig advies;</li>
            <li>planmatig onderhoud.</li>
          </ul>

          <p>
            Dit wordt allemaal vastgelegd in een heldere gedetailleerde offerte.
          </p>

          <h3 className='vhg__subtitle'>Zekerheid</h3>

          <p>
            Als VHG-hovenier overhandigen wij altijd een exemplaar van de
            Algemene Consumentenvoorwaarden die wij hanteren. Zo weet u precies
            waar u aan toe bent. Een goed aangelegde en onderhouden tuin geeft
            meer tuinplezier.
          </p>

          <h3 className='vhg__subtitle'>VHG Garantiecertificaat</h3>

          <p>
            Als VHG-hovenier zorgen wij ervoor dat alle werkzaamheden met de
            grootste zorg worden uitgevoerd. Wij staan volledig achter onze
            geleverde goederen en diensten. Als bewijs daarvan ontvangt u het
            VHG Garantiecertificaat.
          </p>

          <h3 className='vhg__subtitle'>Als er toch iets misgaat</h3>

          <p>
            Mocht u om wat voor reden dan ook niet tevreden zijn, laat het ons
            weten. Komen we er samen niet uit, dan kunt u de zaak voorleggen aan
            de Geschillencommissie. Deze onpartijdige commissie beoordeelt uw
            klacht. Omdat u zaken doet met een VHG-hovenier, heeft u – wanneer
            uw klacht terecht is – de garantie dat fouten en schade worden
            hersteld.
          </p>

          <a href={Voorwaarden} target='_blank'>
            Bekijk hier de VHG Algemene Consumentenvoorwaarden
          </a>
        </section>
      </div>
    </>
  );
};

export default VHGPage;
