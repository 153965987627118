import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Image from '../assets/images/landscaping.jpeg';
import QuotationBanner from '../components/QuotationBanner';
import { BsArrowRightShort } from 'react-icons/bs';
import { Fade, Slide } from 'react-awesome-reveal';

const LandscapingPage = () => {
  return (
    <>
      <Helmet>
        <title>Garden Jobs | Tuinaanleg</title>
      </Helmet>

      <Header title='Tuinaanleg' />

      <div className='landscaping'>
        <section className='container landscaping__container'>
          <div className='landscaping__image'>
            <Slide direction='right' triggerOnce>
              <img
                src={Image}
                alt='Garden Jobs, tuinaanleg, hoveniersbedrijf gespecialiseerd in het onderhoud en de aanleg van tuinen'
              />
            </Slide>
          </div>

          <div className='landscaping__content'>
            <Fade cascade triggerOnce>
              <h2 className='landscaping__title'>
                &ldquo; Een tuin is zo veel meer dan alleen een stukje groen.
                Een tuin is een verlengstuk van uw huis. &rdquo;
              </h2>

              <p className='landscaping__text'>
                Kleur, leven, comfort en sfeer kunnen samen komen in een tuin
                die geheel naar uw wens gemaakt kan worden. Garden Jobs geeft u
                passende adviezen en voorbeelden die bij uw tuin passen.
                <br /> <br />
                Om een mooie tuin te ontwerpen, maakt Garden Jobs gebruik van
                een 3D tuinontwerp programma. Hierdoor heeft u een goed beeld
                van wat het eindresultaat kan gaan worden.
              </p>

              <h3 className='landscaping__subtitle'>
                Voorbeelden van tuinaanleg zijn:
              </h3>
            </Fade>

            <ul className='landscaping__examples'>
              <Fade cascade damping={0.1} triggerOnce>
                <li className='landscaping__example'>
                  <BsArrowRightShort />
                  Bestrating
                </li>
                <li className='landscaping__example'>
                  <BsArrowRightShort />
                  Vijvers
                </li>
                <li className='landscaping__example'>
                  <BsArrowRightShort />
                  Schuttingen
                </li>
                <li className='landscaping__example'>
                  <BsArrowRightShort />
                  Pergola's
                </li>
                <li className='landscaping__example'>
                  <BsArrowRightShort />
                  Schuurtjes
                </li>
                <li className='landscaping__example'>
                  <BsArrowRightShort />
                  Veranda's
                </li>
                <li className='landscaping__example'>
                  <BsArrowRightShort />
                  Beplanting
                </li>
                <li className='landscaping__example'>
                  <BsArrowRightShort />
                  Graszoden
                </li>
                <li className='landscaping__example'>
                  <BsArrowRightShort />
                  Kunstgras
                </li>
                <li className='landscaping__example'>
                  <BsArrowRightShort />
                  Vlonders
                </li>
                <li className='landscaping__example'>
                  <BsArrowRightShort />
                  Beschoeiing
                </li>
                <li className='landscaping__example'>
                  <BsArrowRightShort />
                  Planten bakken
                </li>
              </Fade>
            </ul>
          </div>
        </section>

        <section className='container landscaping__material-section'>
          <Fade cascade triggerOnce>
            <h3 className='landscaping__subtitle'>Materiaal</h3>
            <p>
              Garden Jobs maakt gebruik van verschillende machines en
              gereedschap. Er wordt gewerkt met onder andere een kraan, een
              dumper, een grasmaaier, een trekker en allerlei andere machines
              die nodig zijn om een goed hoveniersbedrijf draaiende te houden.
              Garden Jobs vindt het belangrijk dat het materiaal goede van
              kwaliteit is. Er wordt gebruik gemaakt van gereedschap en machines
              van onder anderen de merken STIHL en Hitachi/Hikoki.
            </p>
          </Fade>
        </section>

        <QuotationBanner bgColor={'#193924'} />
      </div>
    </>
  );
};

export default LandscapingPage;
